body {
    overflow-x: hidden;
    min-height: calc(100vh - 88px);
}

.navbar .nav-link {
    font-size: 14px;
    text-transform: uppercase;
    padding-left: 1rem !important;
    padding-right: 1rem !important;
}

.active {
    border-left: #444 3px solid;
}


.navbar .nav-item.active {
    border-left: #444 3px solid;
}

.carousel-item {
    height: 450px;
}

.car-img-1 {
    background-image: url(./img/image1.jpg);
    background-size: cover;
}

.car-img-2 {
    background-image: url(./img/image2.jpg);
    background-size: cover;
}

.car-img-3 {
    background-image: url(./img/image3.jpg);
    background-size: cover;
}

#home-heading {
    position: relative;
    background-image: linear-gradient(to right bottom, rgba(44, 46, 44, 0.4), rgba(13, 18, 16, 0.4)), url('./img/wires.jpg');
    background-size: cover;
    background-position: center;
    min-height: 200px;
    /*background-attachment: fixed;*/
    /*background-repeat: no-repeat;*/
    text-align: center;
    color: #fff;
}

.dark-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    /*background: rgba(0, 0, 0, 0.7);*/
}

#video-play {
    position: relative;
    min-height: 200px;
    background: url(./img/media.jpg);
    background-repeat: no-repeat;
    background-attachment: fixed;
    text-align: center;
    color: #fff;
}

#video-play a {
    color: #fff;
}

#main-footer {
    background: black;
}

#about-header {
    height: 200px;
    background: url('./img/wires.jpg');
    background-position: 0 -380px;
    /*background-size: cover;*/
    background-attachment: fixed;
    border-bottom: 1px #eee solid;
    padding-top: 50px;
}

.about-img {
    margin-top: -50px;
}

#faq .card {
    background-color: #333;
    border: #444;
}

#faq a {
    text-decoration: blink;
    color: #fff;
}

.hero {
    background-image: linear-gradient(to right bottom, rgba(44, 46, 44, 0.4), rgba(13, 18, 16, 0.4)), url('./img/hero-2.jpg');
    background-size: cover;
    background-position: center;
    border-bottom: 1px #eee solid;
    padding: 50px 0;
}

.hero-heading{
    font-weight: bold;
    letter-spacing: 1.5px;
    font-size: 48px;
    color: #fff;

}

.hero-sub-heading{
    color: #fff;
}

.grid-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    grid-gap: 20px;
}

.logo {
    height: 60px;
    width: auto;
}

.galleryContainer{
    display: grid;
    grid-template-columns: repeat(auto-fit, 500px);
    grid-gap: 20px;
}

.galleryBox{
    height: 250px;
    /*width: 560px;*/
    background-color: red;
}

.galleryImage{
    width: 100%;
    display: block;
    cursor: pointer;
}

.img-chose-us {
    height: 400px;
    width: auto;
}

.pre-nav {
    font-size: 12px;
    padding-bottom: 5px;
    margin-bottom: 10px;
    border-bottom: 1px #5d5858 solid;
}

.pre-nav a {
    color: #fff;
    margin-right: 20px;
}

.dev {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
}

.dev-contact {
    margin-bottom: 0;
    margin-right: 5px;
    display: inline;
}

.dev-contact-link {
    text-decoration: underline;
}